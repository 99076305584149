<template>
    <div class="text-center">
        <v-menu
        :close-on-content-click="false"
        :nudge-width="350"
        offset-x
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" text icon v-bind="attrs" v-on="on">
            <v-icon>info</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-list>
            <v-list-item
                v-for="item in lista"
                :key="item.day"
            >
                <v-list-item-title class="text-lg-h6">{{ item.text }}:</v-list-item-title>

                <v-list-item-subtitle class="font-weight-medium">
                {{ getMoney(item.valor) }}
                </v-list-item-subtitle>

                <v-list-item-icon class="font-weight-black">
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
            </v-list-item>
            </v-list>
        </v-card>
        </v-menu>
    </div>
</template>

<script>
import { getMoney } from '@/common/functions/helpers';

export default {
  data() {
    return {
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoVerbaVariavelListagem(this.$resource),
      lista: [
        {
          text: this.$t('label.apuracao_final'),
          chave: 'valor_apuracao_final',
          valor: 0,
          icon: 'remove',
        },
        {
          text: this.$tc('label.nota_debito', 1),
          chave: 'valor_nd',
          valor: 0,
          icon: 'remove',
        },
        {
          text: this.$tc('status_entidade.reprovado', 1),
          chave: 'valor_reprovado',
          valor: 0,
          icon: 'remove',
        },
        {
          text: this.$t('status_entidade.cancelado'),
          chave: 'valor_cancelado',
          valor: 0,
        },
      ],
    };
  },
  props: {
    codAcao: {
      type: Number,
      default: null,
    },
  },
  methods: {
    getMoney,
    buscarDadosPopoverRecebimentoPendente() {
      this.apuracaoAcaoListagemResource.buscarDadosPopoverRecebimentoPendente({ idAcao: this.codAcao }).then((response) => {
        this.lista.map((item) => {
          Object.keys(response.data).forEach((key) => {
            if (item.chave === key) {
              item.valor = response.data[key];
            }
          });
          return item;
        });
      });
    },
  },
  mounted() {
    this.buscarDadosPopoverRecebimentoPendente();
  },
};
</script>
