<template>
  <div>
    <v-expansion-panels
      focusable
      v-if="!iniciandoPainel"
      v-model="panelExpandidos"
      multiple>

      <v-expansion-panel
        v-for="(categoria, index) in categorias"
        :key="index">

        <v-expansion-panel-header>
          {{ categoria.nome }}
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <apuracao-acao-lista-tabela
            :categoria="categoria"
            :panelExpandido="[index]"/>
        </v-expansion-panel-content>

      </v-expansion-panel>

    </v-expansion-panels>
    <div class="text-center my-3">
      <v-pagination
          v-model="pagina"
          v-if="totalPage > 1"
          :length="totalPage">
      </v-pagination>
      <span v-if="!categorias.length">{{ $t('label.tabela_sem_conteudo') }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { generateComputed } from '@/common/functions/roles-computed-generator';
import ApuracaoAcaoListaTabela from './ApuracaoAcaoListaTabela';

export default {
  name: 'ApuracaoAcaoLista',
  components: {
    ApuracaoAcaoListaTabela,
  },
  mixins: [],
  data() {
    return {
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoVerbaVariavelListagem(this.$resource),
      categorias: [],
      totalPage: 0,
      pagina: 1,
      iniciandoPainel: true,
      tamanhoPagina: 20,
      panelExpandidos: [],
    };
  },
  watch: {
    filtroApuracaoAcao() {
      this.buscarCategorias();
    },
    pagina(valor) {
      this.iniciandoPainel = true;
      this.buscarCategorias(valor);
    },
  },
  computed: {
    ...mapGetters('filtros', [
      'filtroApuracaoAcao',
    ]),
    ...generateComputed('APU_ACAO', [
      'canAccessCRUD',
      'canAccessPage',
    ]),
    ...generateComputed('ACESSO_CLIENTE', [
      'getAllRoles',
    ]),
  },
  methods: {
    buscarCategorias(valor = null) {
      this.categorias = [];
      const filtros = { ...this.filtroApuracaoAcao };
      filtros.numeroPagina = valor || this.pagina;
      filtros.tamanhoPagina = this.tamanhoPagina;

      this.apuracaoAcaoListagemResource.listarApuracoesPorCategoria(filtros)
        .then((response) => {
          this.categorias = response.data.resposta;
          this.totalPage = Math.ceil(response.data.quantidadeRegistrosPagina / this.tamanhoPagina);
          this.iniciandoPainel = false;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      if (!this.getAllRoles
          || !this.getAllRoles.filter((el) => el.startsWith('ACESSO_CLIENTE')).length) {
        window.setTimeout(() => this.accessForbidden(), 1E3);
      }
    }
    if (this.$route.params && this.$route.params.panelExpandido) {
      this.panelExpandidos = this.$route.params.panelExpandido;
    }
  },
};
</script>
<style scoped>
</style>
